import React from 'react';

import UI from '#front-credpago';

import { LogoAnalise, LogoCredPago, LogoCredsign, LogoMultipagamento, LogoSeguro } from '../logos';
import Form from './form';
import * as S from './styles';

const MainContainer = () => {
  return (
    <S.FullWidthWrapper>
      <S.MainContent gutter={1}>
        <UI.Col className="column column--left" responsive={{ md: 7, xs: 12, sm: 12 }}>
          <S.PageTitleRow>
            <UI.Col responsive={{ md: 12, xs: 12, sm: 12 }}>
              <UI.Title
                level={1}
                fontWeight={600}
                splitLabel={['Sua imobiliária pronta', 'para fazer mais negócios']}
              />
              <p>
                Cadastre-se gratuitamente com a CredPago e tenha acesso a uma variedade de ferramentas que aumentam a
                conversão de seus aluguéis e simplificam o dia a dia de sua imobiliária. Descubra todas as soluções e
                recursos que a CredPago pode oferecer à sua imobiliária:
              </p>
            </UI.Col>
          </S.PageTitleRow>

          <S.MeetSolutionsTools>
            <UI.Col responsive={{ md: 12, xs: 12, sm: 12 }}>
              <UI.Row>
                <UI.Col responsive={{ md: 5, xs: 12, sm: 12 }} className="product--logo">
                  <a href={`${process.env.GATSBY_SELF_URL}/aluguel-sem-fiador`} target="_blank" rel="noreferrer">
                    <LogoCredPago style={{ maxWidth: 120 }} />
                  </a>
                </UI.Col>
                <UI.Col responsive={{ md: 7, xs: 12, sm: 12 }} className="product--description">
                  <strong>Garantia de Aluguel</strong>
                  <p>
                    Garantia sem fiador pelas menores taxas do mercado para inquilinos e com a maior segurança para
                    imobiliárias.
                  </p>
                </UI.Col>
              </UI.Row>

              <UI.Row>
                <UI.Col responsive={{ md: 5, xs: 12, sm: 12 }} className="product--logo">
                  <a
                    href={`${process.env.GATSBY_SELF_URL}/aluguel-sem-fiador#como-funciona`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LogoAnalise style={{ maxWidth: 120 }} />
                  </a>
                </UI.Col>
                <UI.Col responsive={{ md: 7, xs: 12, sm: 12 }} className="product--description">
                  <strong>Análise Instantânea</strong>
                  <p>
                    Possuímos uma análise de crédito eficiente e criteriosa feita em menos de 1 minuto. Essa solução é
                    utilizada no serviço de garantia de aluguel
                  </p>
                </UI.Col>
              </UI.Row>

              <UI.Row>
                <UI.Col responsive={{ md: 5, xs: 12, sm: 12 }} className="product--logo">
                  <a
                    href={`${process.env.GATSBY_SELF_URL}/atendimento#multipagamento`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LogoMultipagamento style={{ width: 122 }} />
                  </a>
                </UI.Col>
                <UI.Col responsive={{ md: 7, xs: 12, sm: 12 }} className="product--description">
                  <strong>Multipagamento</strong>
                  <p>
                    Solução que possibilita o inquilino pagar o serviço de garantia de aluguel em múltiplas formas de
                    pagamento: PIX, boleto e cartão de crédito.
                  </p>
                </UI.Col>
              </UI.Row>

              <UI.Row>
                <UI.Col responsive={{ md: 5, xs: 12, sm: 12 }} className="product--logo">
                  <a href={`${process.env.GATSBY_SELF_URL}/credsign`} target="_blank" rel="noreferrer">
                    <LogoCredsign style={{ maxWidth: 160 }} />
                  </a>
                </UI.Col>
                <UI.Col responsive={{ md: 7, xs: 12, sm: 12 }} className="product--description">
                  <strong>CredSign</strong>
                  <p>
                    Assinatura eletrônica para qualquer tipo de documento. Economize tempo e dinheiro com cartório e
                    feche seus contratos com mais agilidade.
                  </p>
                </UI.Col>
              </UI.Row>

              <UI.Row>
                <UI.Col responsive={{ md: 5, xs: 12, sm: 12 }} className="product--logo">
                  <a href={`${process.env.GATSBY_SELF_URL}/seguro`} target="_blank" rel="noreferrer">
                    <LogoSeguro style={{ maxWidth: 168 }} />
                  </a>
                </UI.Col>
                <UI.Col responsive={{ md: 7, xs: 12, sm: 12 }} className="product--description">
                  <strong>Seguro Residencial</strong>
                  <p>
                    Seguro para imóveis alugados que protege contra raio, incêndio, explosão e vendavais. Contratado
                    junto com a garantia pelo menor preço do mercado.
                  </p>
                </UI.Col>
              </UI.Row>
            </UI.Col>
          </S.MeetSolutionsTools>
        </UI.Col>
        <UI.Col className="column column--right" responsive={{ md: 5, xs: 12, sm: 12 }}>
          <Form />
        </UI.Col>
      </S.MainContent>
    </S.FullWidthWrapper>
  );
};

export default MainContainer;
