export const formatReais = (value) => {
  const v = `${(value.toString().replace(/[^0-9]/g, '') / 100).toFixed(2)}`.split('.');
  const m = v[0]
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g);

  for (let i = 0; i < m.length; i++) {
    m[i] = `${m[i].split('').reverse().join('')}.`;
  }

  const r = m.reverse().join('');

  const result = `R$ ${r.substring(0, r.lastIndexOf('.'))},${v[1]}`;

  return result === 'R$ 0,00' ? '' : result;
};

export const formatPhone = (value) => {
  const isCellphone = [11, 15].includes(value.length);
  let formatted = value;

  if (isCellphone) {
    formatted = value.replace(/(\d{2})(\d{5})(\d{4})/, (regex, val1, val2, val3) => {
      return `(${val1}) ${val2}-${val3}`;
    });
  } else {
    formatted = value.replace(/(\d{2})(\d{4})(\d{4})/, (regex, val1, val2, val3) => {
      return `(${val1}) ${val2}-${val3}`;
    });
  }

  return formatted;
};

export const formatCEP = (value) => {
  const cleanValue = value.replace(/[^0-9]/g, '');
  let formatted = value;

  if (cleanValue.length === 8) {
    formatted = cleanValue.replace(/(\d{5})(\d{3})/, (regex, val1, val2) => {
      return `${val1}-${val2}`;
    });
  }

  return formatted;
};

export const formatCNPJ = (string) => {
  return string.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const formatCPF = (string) => {
  return string.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};
