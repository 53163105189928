import { validateCNPJ, validateCPF } from 'validations-br';
import * as yup from 'yup';

import { hasNumber, hasSpecialChars, hasTwelveOrMoreChars, hasUpperAndLowerCase, validateBlankSpace } from './fields';

export const cadastreSchema = yup.object().shape({
  corretor_autonomo: yup.number(),
  nome: yup.string().required().test('nome', 'Não é permitido espaços adicionais', validateBlankSpace),
  email: yup.string().email().required().test('email', 'Não é permitido espaços adicionais', validateBlankSpace),
  telefone: yup
    .string()
    .phone('BR', false, 'Telefone precisa ser um número de telefone válido')
    .required()
    .test('telefone', 'Não é permitido espaços adicionais', validateBlankSpace),
  cpf: yup.string().when('corretor_autonomo', {
    is: (value) => value === 1,
    then: yup
      .string()
      .test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
      .required(),
  }),
  cnpj: yup.string().when('corretor_autonomo', {
    is: (value) => value === 0,
    then: yup
      .string()
      .test('is-cnpj', 'CNPJ não é válido', (value) => validateCNPJ(value))
      .required()
      .test('cnpj', 'Não é permitido espaços adicionais', validateBlankSpace),
  }),
  id_cidade_ibge: yup.string().required(),
  uf: yup.string().required().test('uf', 'Não é permitido espaços adicionais', validateBlankSpace),
  carteira: yup.string().required(),
  creci: yup.string().required('Por favor selecione uma CRECI valido'),
  senha: yup
    .string()
    .test('senha', 'Senha deve possuir no mínimo 12 caracteres', hasTwelveOrMoreChars)
    .test('senha', 'Senha deve possuir letras maiúsculas e minúsculas', hasUpperAndLowerCase)
    .test('senha', 'Senha deve possuir números de 0 a 9', hasNumber)
    .test('senha', 'Senha deve possuir caracteres especiais como @#$%&;', hasSpecialChars),
  senha_confirmacao: yup
    .string()
    .oneOf([yup.ref('senha'), null], 'Deve ser igual à senha')
    .when('senha', {
      is: (value) => value !== '',
      then: yup.string().required(),
    }),
});
