import styled from 'styled-components';

import UI from '#front-credpago';

import globals from '../globals';

const { containerMaxWidth, fonts } = globals;

export const FullWidthWrapper = styled.section`
  width: 100%;
  max-width: calc(${containerMaxWidth} + 40px);
  margin: 0 auto;
  margin-top: 65px;
  align-self: center;
  position: relative;
`;

export const MainContent = styled(UI.Row)`
  h1 {
    font-family: ${fonts.headings};
    padding-left: 20px;
    font-size: 76px;
  }

  .column--left {
    .product--logo {
      .col-inner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
        }
      }
    }

    .product--description {
      padding-left: 0;
    }
  }

  .column--right {
    > .col-inner {
      height: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .gatsby-image-wrapper {
        width: 100%;
        max-width: 324px;
      }
    }
  }
`;

export const PageTitleRow = styled(UI.Row)`
  h1 {
    padding-left: 0;
    font-size: 56px;
    display: flex;
    flex-direction: column;
  }

  p {
    color: #7d7d7d;
    font-size: 15px;
    margin-top: 40px;
    width: 85%;
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    h1 {
      font-size: 33px;
    }

    p {
      width: 100%;
    }
  }
`;

export const MeetSolutionsTools = styled(UI.Row)`
  svg {
    width: 100%;
    height: 100px;
  }

  strong {
    color: #001c4a;
    font-size: 15px;
  }

  p {
    color: #7d7d7d;
    font-size: 15px;

    &.meet-text {
      margin-bottom: 31px;
    }
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    p {
      color: #7d7d7d;
      font-size: 15px;

      &.meet-text {
        margin-bottom: 0;
      }
    }
  }
`;

export const CustomForm = styled.form`
  padding: 0 40px;
  margin-top: 180px;

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    margin-top: 0;
  }

  > .grid-row {
    .col {
      padding: 10px 0;

      .col-inner {
        padding: 0px 10px;
      }
    }
  }

  .button-submit-container {
    margin-top: 15px;

    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #fcfcfc;
      min-height: 56px;
    }
  }
`;

export const AlertWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`;
