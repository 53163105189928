export const validateBlankSpace = (value) => {
  const regex = new RegExp(/\s\s/g);
  const startWithBlankSpace = value?.startsWith(' ');
  const endWithBlankSpace = value?.endsWith(' ');
  const hasMoreThanOneSpace = regex.test(value);
  return !startWithBlankSpace && !endWithBlankSpace && !hasMoreThanOneSpace;
};

export const handleBlankSpaces = (value, removeAllSpaces) => {
  if (removeAllSpaces) {
    return value?.replace(/\s/g, '');
  }
  if (!validateBlankSpace(value)) {
    let cleanFieldValue = value.replace(/\s{2,}/g, ' ')?.trim();
    return cleanFieldValue;
  }
  return value;
};

export const handleNumbers = (value) => {
  var specialChars = value.replace(/\D/g, '')?.trim();
  return specialChars;
};

/**
 *
 * @param {string} value
 * @return {boolean}
 */
export const hasTwelveOrMoreChars = (value) => {
  return value.length >= 12;
};

/**
 *
 * @param {string} value
 * @return {boolean}
 */
export const hasUpperAndLowerCase = (value) => {
  return /[A-Z]/.test(value) && /[a-z]/.test(value);
};

/**
 *
 * @param {string} value
 * @return {boolean}
 */
export const hasNumber = (value) => {
  return /[0-9]/.test(value);
};

/**
 *
 * @param {string} value
 * @return {boolean}
 */
export const hasSpecialChars = (value) => {
  return /[!|@#$%^&*()\-_]/.test(value);
};
