import React from 'react';

import MainContainer from '../components/cadastre/main';
import Layout from '../components/layout/index';
import SEO from '../components/seo';

const CadastroPage = () => {
  return (
    <Layout internal={true} hasChatButton={true}>
      <SEO title="Cadastre-se" />
      <MainContainer />
    </Layout>
  );
};

export default CadastroPage;
